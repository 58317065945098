export const API_URL = `${process.env.REACT_APP_API_URL}/api`

export const GET_MASTER_DATA = `${API_URL}/get-master`
export const GET_UNITS = `${API_URL}/units`
export const GET_UNITS_LIST = `${GET_UNITS}/all`
export const GET_BRAND_LIST = `${API_URL}/brands/all`
export const ADD_BRAND = `${API_URL}/brands`
export const GET_CATEGORIES_LIST = `${API_URL}/categories/all`
export const GET_SUB_CATES = `${API_URL}/categories/get-sub`
export const GET_SUPPLIERS_LIST = `${API_URL}/suppliers/all`
export const ADD_SUPPLIERS = `${API_URL}/suppliers`
export const ADD_CATEGORIES = `${API_URL}/categories`
export const GET_QUARRANTY_LIST = `${API_URL}/quarranty/all`
export const ADD_QUARRANTY = `${API_URL}/quarranty`
export const GET_AGENCY_OPTIONS = `${API_URL}/common/get-agency-options`
export const GET_SUPPLIER_OPTIONS = `${API_URL}/common/get-supplier-options`

export const GET_USER_BY_ACCESS_TOKEN_URL = `${API_URL}/auth/get-user`
export const LOGIN_URL = `${API_URL}/login`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

export const ADMIN_AGENT_ENDPOINT = `${API_URL}/agents`
// products
export const ADMIN_PRODUCTS_ENDPOINT = `${API_URL}/inventory`
export const ADMIN_CHECKING_PRODUCT_ENDPOINT = `${ADMIN_PRODUCTS_ENDPOINT}/checking`
export const ADMIN_AGENT_GROUP_ENDPOINT = `${API_URL}/agent-group`

// accounts
export const ADMIN_ACCOUNTS_ENDPOINT = `${API_URL}/accounts`
// roles
export const ADMIN_ROLES_ENDPOINT = `${API_URL}/roles`
// permissions
export const ADMIN_PERMISSIONS_ENDPOINT = `${API_URL}/permissions`

export const ADMIN_STORES_ENDPOINT = `${API_URL}/stores`

export const ADMIN_AGENT_PRODUCT_ENDPOINT = `${API_URL}/agent_product`

export const ADMIN_ORDER_ENDPOINT = `${API_URL}/orders`

export const ADMIN_WAREHOUSE_ENDPOINT = `${API_URL}/warehouse`

export const ADMIN_RECEIPT_ENDPOINT = `${API_URL}/receipt`

export const ADMIN_INVENTORY_CHECK_SHEET_ENDPOINT = `${API_URL}/inventory-check-sheet`

export const ADMIN_REFUND_ENDPOINT = `${API_URL}/refund`

export const ADMIN_WARRANTY_REFUND_ENDPOINT = `${API_URL}/warranty_refund`

export const ADMIN_SUPPLIER_ENDPOINT = `${API_URL}/suppliers`
export const ADMIN_SUPPLIER_HISTORY_ENDPOINT = `${API_URL}/receipt/get-by-supplier`
export const ADMIN_ABILITIES_ENDPOINT = `${API_URL}/abilities`

export const ADMIN_CART_ENDPOINT = `${API_URL}/cart`

export const GET_UNIT_OPTIONS = `${API_URL}/common/get-unit-options`

export const GET_AGENCY_DEBT = `${API_URL}/debt/agency`
export const GET_SUPPLIER_DEBT = `${API_URL}/debt/supplier`
export const ADMIN_INVENTORY_SUPPLIER = `${API_URL}/inventory-supplier`

export const ADMIN_DASHBOARD_ENDPOINT = `${API_URL}/dashboard`
export const ADMIN_CATEGORY_ENDPOINT = `${API_URL}/categories`
export const ADMIN_UNIT_ENDPOINT = `${API_URL}/units`
export const ADMIN_WARRANTY_ENDPOINT = `${API_URL}/warranty`
export const ADMIN_BRAND_ENDPOINT = `${API_URL}/brands`
export const GET_SALES_OPTIONS = `${API_URL}/common/get-sales-options`
export const ADMIN_SALES_ENDPOINT = `${API_URL}/sales`
export const ADMIN_GET_LOG_HIST_ENDPOINT = `${API_URL}/get-login-hist`
export const ADMIN_EXPORT_LOG_HIST_ENDPOINT = `${API_URL}/export-login-hist`
export const GET_USER_OPTIONS = `${API_URL}/common/get-users-options`
export const GET_MY_ACCOUNT_INFO = `${API_URL}/get-my-account`
export const UPDATE_MY_ACCOUNT_INFO = `${API_URL}/update-my-account-info`
