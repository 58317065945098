import React, { Suspense, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../_metronic/partials'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import AccountWrapper from "../pages/accounts/AccountWrapper";
import GrantAccessWrapper from "../pages/accounts/GrantAccessWrapper";
import StoreWrapper from "../pages/store/StoreWrapper";
import { usePermissionChecker } from '../../app/hooks/PermissionChecker'
import { LIST_PERMISSIONS } from '../../app/constants/common'
import SupplierWrapper from '../pages/suppliers/SupplierWrapper'
import AbilitiesWrapper from '../pages/abilities/AbilitiesWrapper'
import ReceiptVoucherWrapper from '../pages/abilities/receipt-voucher/ReceiptVoucherWrapper'
import PaymentVoucherWrapper from '../pages/abilities/agency/PaymentVoucherWrapper'
import AgencyDebtWrapper from '../pages/debt/agency/AgencyDebtWrapper'
import SupplierDebtWrapper from '../pages/debt/supplier/SupplierDebtWrapper'
import SalesReportWrapper from '../pages/debt/SalesReportWrapper'
import DetailSearchWrapper from '../pages/order/detail-search/DetailSearchWrapper'
import InventoryDeliveryWrapper from '../pages/warehouse/InventoryDelivery/InventoryDeliveryWrapper'
import InventoryDeliveryDetailWrapper from '../pages/warehouse/InventoryDelivery/detail/InventoryDeliveryDetailWrapper'
import ReceiptProductsWrapper from '../pages/warehouse/receipt/detail-search/ReceiptProductsWrapper'
import InventoryDeliveryProductsWrapper from '../pages/warehouse/InventoryDelivery/detail-search/InventoryDeliveryProductsWrapper'
import SalesProductWrapper from '../pages/add-sales-products/SalesProductWrapper'
import AddProductsWrapper from '../pages/add-sales-products/create/AddProductsWrapper'
import AccountInfoWrapper from '../pages/personnel-info/AccountInfoWrapper'


export function PrivateRoutes() {
  const { hasPermission } = usePermissionChecker();
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProductsWrapper = lazy(() => import('../pages/products/ProductsWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const AgentWrapper = lazy(() => import('../pages/agent/AgentWrapper'))
  const AgentDetailWrapper = lazy(() => import('../pages/agent-detail/AgentDetailWrapper'))
  const AgentProductWrapper = lazy(() => import('../pages/agent-detail/product/AgentProductWrapper'))
  const OrderWrapper = lazy(() => import('../pages/order/OrderWrapper'))
  const OrderDetailWrapper = lazy(() => import('../pages/order/detail/OrderDetailWrapper'))
  const WareHouseWrapper = lazy(() => import('../pages/warehouse/WareHouseWrapper'))
  const ReceiptWrapper = lazy(() => import('../pages/warehouse/receipt/ReceiptWrapper'))
  const ReceiptDetailWrapper = lazy(() => import('../pages/warehouse/receipt/detail/ReceiptDetailWrapper'))
  const CartWrapper = lazy(() => import('../pages/warehouse/cart/CartWrapper'))
  const InventoryCheckSheetWrapper = lazy(() => import('../pages/warehouse/inventory-check-sheet/InventoryCheckSheetWrapper'))
  const InventoryCheckSheetDetailWrapper = lazy(() => import('../pages/warehouse/inventory-check-sheet/detail/InventoryCheckSheetDetailWrapper'))
  const WarrantyWrapper = lazy(() => import('../pages/warehouse/warranty/WarrantyWrapper'))
  const WarrantyDetailWrapper = lazy(() => import('../pages/warehouse/warranty/detail/WarrantyDetailWrapper'))
  const LogHistWrapper = lazy(() => import('../pages/login-histtory/LogHistWrapper'))

  const renderRoute = (path: string, component: any, requiredPermission?: string) => {
    if (requiredPermission && !hasPermission(requiredPermission)) {
      return <Redirect to="/error/404" />;
    }
    return <Route path={path} component={component} />;
  };

  const protectedRoutes = [
    { path: '/dashboard', component: DashboardWrapper, permission: LIST_PERMISSIONS.DASHBOARD },
    { path: '/accounts/list', component: AccountWrapper, permission: LIST_PERMISSIONS.ACCOUNTS },
    { path: '/account/add', component: AccountWrapper, permission: LIST_PERMISSIONS.ACCOUNTS },
    { path: '/account/edit/:id', component: AccountWrapper, permission: LIST_PERMISSIONS.ACCOUNTS },
    { path: '/accounts/roles', component: GrantAccessWrapper, permission: LIST_PERMISSIONS.ACCOUNTS },
    { path: '/stores', component: StoreWrapper, permission: LIST_PERMISSIONS.STORES },
    { path: '/debt/agency', component: AgencyDebtWrapper, permission: LIST_PERMISSIONS.DEBTS},
    { path: '/debt/supplier', component: SupplierDebtWrapper, permission: LIST_PERMISSIONS.DEBTS},
    { path: '/debt/sales-report', component: SalesReportWrapper, permission: LIST_PERMISSIONS.DEBTS},
  ];

  const normalRoutes = [
    { path: '/', component: DashboardWrapper },
    { path: '/dashboard', component: DashboardWrapper },
    { path: '/builder', component: BuilderPageWrapper },
    { path: '/crafted/pages/profile', component: ProfilePage },
    { path: '/crafted/pages/wizards', component: WizardsPage },
    { path: '/crafted/widgets', component: WidgetsPage },
    { path: '/crafted/account', component: AccountPage },
    { path: '/apps/chat', component: ChatPage },
    { path: '/menu-test', component: MenuTestPage },
    { path: '/products', component: ProductsWrapper },
    { path: '/products/detail', component: ProductsWrapper },
    { path: '/products/detail/:id?', component: ProductsWrapper },
    { path: '/agents', component: AgentWrapper },
    { path: '/agents/detail/:id?', component: AgentDetailWrapper },
    { path: '/agents/detail/:id/product', component: AgentProductWrapper },
    { path: '/orders', component: OrderWrapper },
    { path: '/orders/detail/:id?', component: OrderDetailWrapper },
    { path: '/warehouse/list', component: WareHouseWrapper },
    { path: '/warehouse/receipt/list', component: ReceiptWrapper },
    { path: '/warehouse/receipt/detail/:id?', component: ReceiptDetailWrapper },
    { path: '/warehouse/receipt/find-products', component: ReceiptProductsWrapper },
    { path: '/warehouse/inventory-delivery/list', component: InventoryDeliveryWrapper },
    { path: '/warehouse/inventory-delivery/detail/:id?', component: InventoryDeliveryDetailWrapper },
    { path: '/warehouse/inventory-delivery/find-products', component: InventoryDeliveryProductsWrapper },
    { path: '/warehouse/cart', component: CartWrapper },
    { path: '/warehouse/inventory-check-sheet', component: InventoryCheckSheetWrapper },
    { path: '/warehouse/inventory-check-sheet/detail/:id?', component: InventoryCheckSheetDetailWrapper },
    { path: '/warehouse/warranty_refund', component: WarrantyWrapper },
    { path: '/warehouse/warranty_refund/detail/:id?', component: WarrantyDetailWrapper },
    { path: '/suppliers', component: SupplierWrapper },
    { path: '/abilities/index', component: AbilitiesWrapper },
    { path: '/abilities/receipt-voucher', component: ReceiptVoucherWrapper },
    { path: '/abilities/payment-voucher', component: PaymentVoucherWrapper },
    { path: '/orders/product-search', component: DetailSearchWrapper },
    { path: '/sales-products', component: SalesProductWrapper },
    { path: '/sales-products/add', component: AddProductsWrapper },
    { path: '/auth-logs', component: LogHistWrapper },
    { path: '/my-account', component: AccountInfoWrapper }
  ];
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        {protectedRoutes.map((route) => (
          <Route key={route.path} path={route.path}>
            {renderRoute(route.path, route.component, route.permission)}
          </Route>
        ))}
        {normalRoutes.map((route) => (
          <Route exact key={route.path} path={route.path} component={route.component} />
        ))}
        {/*<Redirect from='/' to='/dashboard' />*/}
      </Switch>
    </Suspense>
  );
}
